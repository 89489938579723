.notification {
	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	p {
		//line-height: 32px;
	}

	&.is-primary {
		padding-right: 1.5rem;
	}

	.icon {
		&.is-pulled-left {
			margin-right: 10px;
		}

		&.is-pulled-right {
			position: absolute;
			right: 0.75rem;
			bottom: 0.75rem;
		}
	}

	.level-left,
	.level-right,
	.level-item {
		flex-shrink: initial;
	}

	@include breakpoint(mobile-only) {
		.level-left,
		.level-right {
			.level-item {
				margin-right: 0;
			}
		}

		.level-right {
			.button {
				width: 100%;
			}
		}
	}

	@include breakpoint(tablet-and-up) {
		.level-left {
			+ .level-right {
				margin-left: 0.75rem;
			}
		}
	}
}
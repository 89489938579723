@include breakpoint(mobile-only) {
	.section {
		padding: 15px 0.75rem;
	}

	/*.columns {
		margin-top: -2px;
		margin-right: -2px;
		margin-left: -2px;

		&:last-child {
			margin-bottom: -2px;
		}
	}

	.column {
		padding: 2px;
	}*/
}

.columns {
	&.is-gapless {
		margin-top: -1px;
		margin-right: -1px;
		margin-left: -1px;

		> .column {
			padding: 1px;
		}
	}
}

.column {
	order: 2;

	&.left {
		order: 1;
	}

	&.right {
		order: 3;
	}

	&.sidebar {
		width: 324px;

		&.responsive {
			@include breakpoint(tablet-only) {
				width: 181px;
			}
		}

		@include breakpoint(mobile-only) {
			width: auto;
		}
	}

	&.sidebar-narrow {
		width: 200px;
	}
}
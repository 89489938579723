.select {
	select {
		width: 100%;
	}
}

p {
	+ form {
		padding-top: 0.5em;
	}
}
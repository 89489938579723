.modal {
	&:target {
		display: flex;
	}

	.modal-content,
	.modal-card {
		@include breakpoint(tablet-and-up) {
			max-width: calc(100vw - 40px);
			width: 800px;
		}
	}
}
html {
	@include breakpoint(tablet-and-down) {
		font-size: 14px;
	}
}

.hidden {
	display: none;
}

iframe {
	max-width: 100%;
}
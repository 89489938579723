@keyframes progress {
	from {
		height: 100%;
	}

	to {
		height: 0;
	}
}

@keyframes hide-live-notification {
	from {
		opacity: 1;
	}

	50% {
		height: auto;
		margin-top: 20px;
		padding: 20px;

		opacity: 0;
	}

	100% {
		height: 0;
		margin-top: 0;
		padding: 0;

		opacity: 0;
	}
}

$notification-bar-width: 3px;

#live-notifications {
	min-width: 260px;

	position: fixed;
	//right: 30px;
	bottom: 30px;
	left: 30px;

	z-index: 1985;

	@include breakpoint(mobile-only) {
		right: 20px;
		bottom: 20px;
		left: 20px;
	}
}

	.live-notification {
		width: 100%;
		max-width: 400px;
		padding: 15px;

		display: flex;
		align-items: center;

		position: relative;

		overflow: hidden;

		background-color: #FFF;

		border-left: $notification-bar-width $primary solid;

		box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);

		//transition: transform 400ms;

		&.has-lifetime {
			padding-left: 15px + $notification-bar-width;

			animation-name: hide-live-notification;
			animation-duration: 400ms;
			animation-fill-mode: forwards;
			animation-play-state: running;

			border-left-width: 0 !important;
		}

		&.is-success {
			border-left-color: $success;
		}

		&.is-warning {
			border-left-color: $warning;
		}

		&.is-danger {
			border-left-color: $danger;
		}

		/*.delete {
			position: absolute;
			top: 6px;
			right: 6px;

			.touchevents &,
			&.is-persistent {
				display: none;
			}
		}

		.no-touchevents & {
			padding-right: 30px;

			&.is-persistent {
				padding-right: 15px;
			}
		}*/

		.delete {
			display: none; //LOOKS BETTER WITHOUT
		}

		+ .live-notification {
			margin-top: 10px;
		}
	}

		.live-notification-message,
		.live-notification-buttons {
			display: flex;

			overflow: hidden;
			overflow-x: auto;
		}

		.live-notification-message {
			padding: 5px 0 5px 5px;

			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			justify-content: flex-start;
		}

		.live-notification-buttons {
			flex: none;
			justify-content: flex-end;

			white-space: nowrap;

			&:empty {
				display: none;
			}

			.button {
				margin-left: 10px;

				display: flex;
				flex-grow: 0;
				flex-shrink: 0;
				justify-content: center;

				@include breakpoint(mobile-only) {
					//display: block;
				}

				&:first-child {
					margin-left: 20px;
				}

				&.dismiss {
					.touchevents & {
						display: none;
					}
				}
			}
		}

		.live-notification-status {
			width: $notification-bar-width;
			height: 0;

			position: absolute;
			bottom: 0;
			left: 0;

			background-color: $primary;

			animation-name: progress;
			animation-timing-function: linear;
			animation-delay: 0s;
			animation-play-state: running;
		}
.image {
	&.is-2by3 {
		padding-top: 150%;

		img {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	& + .image {
		margin-top: 0.75rem;
	}

	&.advert {
		width: 100%;
		margin: 0 auto;

		img {
			width: auto;
		}

		ins {
			display: block;
		}

		& + .image {
			//margin-top: 0;
		}

		&.half-page,
		&.medium-rectangle,
		&.three-one-rectangle {
			max-width: 300px;
		}

		&.wide-skyscraper {
			max-width: 160px;
		}

		&.leaderboard {
			max-width: 728px;
		}

		&.pop-under {
			max-width: 720px;
		}
	}
}
.card {
	overflow: hidden;

	.card-label {
		height: 20px;
		padding: 0 10px;

		position: absolute;
		top: 10px;
		right: 0;

		color: #FFF;
		line-height: 20px;
		text-transform: uppercase;

		background-color: $dark;

		z-index: 2;

		&.is-danger {
			background: $danger;
		}

		&.is-warning {
			background: $warning;
		}

		+ .card-image {
			z-index: 1;
		}
	}

	+ .image {
		margin-top: 20px;
	}
}
.section {
	&[itemtype="http://schema.org/ImageGallery"] {
		//background-color: $light;
	}
}

.gallery-wrapper {
	max-width: $widescreen;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0;
	padding-left: 0;

	overflow: hidden;

	.tabs,
	.notification {
		margin-right: 1.5rem;
		margin-left: 1.5rem;

		@include breakpoint(tablet-and-down) {
			margin-right: 0.75rem;
			margin-left: 0.75rem;
		}
	}

	.tabs {}

	.notification {}
}

.gallery {
	&.all-loaded {
		+ .loading-more {
			display: none;
		}
	}

	figure {
		height: 0;
		//padding-bottom: 100%;
		padding-bottom: ( ( 2 / 3 ) * 100% );

		position: relative;

		background-color: #FFF;

		a {
			display: block;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			overflow: hidden;

			img {
				width: auto;
				height: 100%;
				max-height: 100%;
				margin: 0 auto;

				.csstransforms & {
					position: absolute;
					top: 50%;
					left: 50%;

					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
				}

				&.no-thumb {
					//width: 100%;
					height: 100%;

					object-fit: contain;
					font-family: 'object-fit: contain;'
				}
			}
		}

		&.advert {
			height: auto;
			margin-top: 0.75rem;
			margin-bottom: 0.75rem;
			padding-bottom: 0;
		}
	}

	iframe {
		margin: 0.75rem auto;

		display: block;
	}
}

.pswp--fs {
	.pswp__bg {
		opacity: 1 !important;
	}
}

.pswp__button {
	&.pswp__button--download,
	&.pswp__button--report,
	&.pswp__button--favourite,
	&.pswp__button--share {
		background-image: url("../images/photoswipe/custom.png");
		background-size: 132px 88px;

		@media (-webkit-min-device-pixel-ratio: 1.1), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
			.pswp--svg & {
				background-image: url("../images/photoswipe/custom.svg");
			}
		}
	}

	&.pswp__button--download {
		background-position: 0 0;
	}

	&.pswp__button--report {
		background-position: -44px 0;
	}

	&.pswp__button--share {
		background-position: -88px 0;
	}

	&.pswp__button--favourite {
		background-position: 0 -44px;
		//background-position: -44px -44px;
	}
}

.pswp__share-tooltip {
	a {
		&.pswp__share--facebook {
			&:hover {
				&::before {
					border-bottom-color: #FFF;
				}
			}
		}

		&.pswp__share--download,
		&.pswp__share--report {
			&:hover {
				background-color: $light;
			}
		}

		.fa {
			vertical-align: baseline;
		}
	}
}
.hero {
	position: relative;

	//overflow: hidden;

	background-size: cover;
	background-position: center;
	background-attachment: fixed;

	z-index: 11;

	@include breakpoint(tablet-and-up) {
		background-image: url("/app/Resources/images/banner-background.jpg");
	}

	&.is-fullheight {
		background-image: url("/app/Resources/images/banner-background.jpg");

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			background-color: rgba(0, 0, 0, 0.3);

			z-index: 2;

			content: "";
		}

		.hero-head {
			background-color: rgba(0, 0, 0, 0.5);
		}

		.hero-body {
			.container {
				max-width: 550px;
				margin: 0 auto;
			}
		}
	}

	.preview-photos {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		overflow: hidden;

		z-index: 1;

		.preview-photo {
			max-width: 40%;
			width: 350px;

			position: absolute;
			//position: fixed;

			border: 8px #FFF solid;

			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25), 0 5px 25px rgba(0, 0, 0, 0.25);

			-webkit-transition: -webkit-transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
			transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

			-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
			//-webkit-transform: scale(0.5) translateY(-50%) rotate(5deg);
			//transform: scale(0.5) translateY(-50%) rotate(5deg);

			@include breakpoint(mobile-only) {
				border-width: 5px;
			}

			/*&.top {
				margin-top: -20px;

				top: 0;

				-webkit-transform-origin: 0 50%;
				transform-origin: 0 50%;
				-webkit-transform: scale(0.5) translateX(-50%) translateY(-100%);
				transform: scale(0.5) translateX(-50%) translateY(-100%);
			}*/

			&.left {
				margin-left: -20px;

				left: 0;

				-webkit-transform-origin: 0 50%;
				transform-origin: 0 50%;
				-webkit-transform: scale(0.5) translateX(-100%) translateY(-50%);
				transform: scale(0.5) translateX(-100%) translateY(-50%);
			}

			&.right {
				margin-right: -20px;

				right: 0;

				-webkit-transform-origin: 100% 50%;
				transform-origin: 100% 50%;
				-webkit-transform: scale(0.5) translateX(100%) translateY(-50%);
				transform: scale(0.5) translateX(100%) translateY(-50%);
			}

			img {
				width: 100%;

				display: block;
			}
		}
	}

	.nav {
		box-shadow: none !important;

		.nav-right {
			@include breakpoint(mobile-only) {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}

	.title {
		font-weight: 500;
	}

	hr {
		background-color: rgba(255, 255, 255, 0.35);
	}

	/*~ .nav {
		position: relative;

		z-index: 5;
	}

	~ .section,
	~ .footer {
		position: relative;

		z-index: 4;
	}*/
}

.hero-head,
.hero-body,
.hero-foot {
	position: relative;
}

.hero-head {
	//height: 52px;

	background-color: rgba(0, 0, 0, 0.5);

	z-index: 4;

	@include breakpoint(tablet-and-up) {
		height: 62px;
	}

	@include breakpoint(mobile-only) {
		background-color: rgba(0, 0, 0, 0.2);
	}

	.nav {
		height: 100%;
	}
}

.hero-body,
.hero-foot {
	z-index: 3;
}

.hero-body {
	padding-top: 3rem;
	padding-bottom: 3rem;

	//text-shadow: 0 0 25px rgba(0, 0, 0, 0.25);

	@include breakpoint(mobile-only) {
		padding-top: 2rem;
		padding-bottom: 2rem;

		.title.is-1 {
			font-size: 2.5rem;
		}

		.subtitle {
			&.is-3 {
				font-size: 1.5rem;
			}

			&.is-4 {
				font-size: 1.25rem;
			}
		}
	}
}

.hero-foot {
	padding: 0 20px;

	//text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

	.level {
		max-width: 700px;
		margin: 0 auto;
		padding: 40px 0;
	}
}
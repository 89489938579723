.control {
	&.is-grouped {
		&.is-pulled-right {
			margin-bottom: 0;
			padding-top: 0;

			@include breakpoint(mobile-only) {
				//float: none;
			}
		}
	}
}
p {
	//padding-bottom: 0.5em;

	strong {
		color: inherit;
	}

	+ p {
		.is-grouped &.control {
			//padding-top: 0;
		}
	}
}

dl {
	display: flex;
	flex-wrap: wrap;

	border-top: 2px $light solid;
	border-bottom: 1px $light solid;
}

dt,
dd {
	padding: 0.5rem;

	flex: none;

	border-bottom: 1px $light solid;
}

dt {
	width: 35%;

	font-weight: bold;
}

dd {
	width: 65%;
}
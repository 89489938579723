@include breakpoint(tablet-only) {
	.hero {
		+ .nav {
			//padding-right: 7px;
			//padding-left: 7px;
		}
	}
}

.nav {
	max-width: 100%;

	&.has-shadow {
		position: -webkit-sticky;
		position: sticky;
		top: -1px;
	}
}

.nav-item a,
a.nav-item {
	&:not(.button) {
		color: $text;
	}
}

.nav-left {
	//width: 100%;

	//overflow: auto;

	@include breakpoint(tablet-only) {
		padding-right: 7px;
		padding-left: 7px;
	}

	.nav-item {
		img {
			max-height: 32px;

			+ span {
				margin-left: 8px;
				padding-top: 16px;

				display: inline-block;

				font-size: 0.85rem;
				font-weight: 300;
			}
		}
	}
}

.nav-right {
	.nav-item {
		.icon {
			+ span {
				margin-left: 10px;
			}
		}
	}
}

@include breakpoint(mobile-only) {
	.nav-menu {
		&.is-visible-mobile {
			display: block;
		}
	}
}
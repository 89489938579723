.subscription-menu {
	display: none;
}

.subscribe {
	display: none;

	//color: $grey-light;

	cursor: pointer;

	input[type="checkbox"] {
		width: 0;
		height: 0;

		position: absolute;

		visibility: hidden;

		&:checked {
			~ .icon {
				//color: $text;

				.fa-bell-o {
					&::before {
						content: "\f0f3";
					}
				}

				.fa-circle-thin {
					&::before {
						content: "\f058";
					}
				}

				.fa-square-o {
					&::before {
						content: "\f046";
					}
				}
			}
		}

		&:disabled {
			~ .icon {
				//color: $grey-dark;

				.fa {
					-webkit-animation: fa-spin 2s infinite linear;
					animation: fa-spin 2s infinite linear;

					&::before {
						content: "\f013";
					}
				}
			}
		}
	}

	&:hover {
		//color: $text;
	}
}